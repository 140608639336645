import { React, useEffect, useState, useRef } from "react";
import {
  CCard,
  CCardBody,
  CContainer,
  CCardImage,
  CCardText,
  CRow,
  CCol,
  CForm,
  CFormInput,
  CFormSwitch,
  CFormLabel,
  CFormSelect,
  CButton,
  CSpinner,
} from "@coreui/react";
import Select from "react-select";
import adb from "../images/adbc.png";
import addpics from "../images/additional.png";
import { useProductListingContext } from "../context/productListingContext";
import { options } from "joi-browser";
import { packaging } from "./../fakedata/packaging";
import { FcMoneyTransfer } from "react-icons/fc";
import { toast } from "react-toastify";
import { useUserContext } from "../context/userContext";
import { additionalRequest } from "../services/generalService";

const AdditionalBarcodes = () => {
  const { gtinFees } = useProductListingContext();
  const { registrationid } = useUserContext();
  const [maxImage, setMaxImage] = useState();
  const [loading, setLoading] = useState(false);
  const [additionalsInfo, setAdditionalInfo] = useState({
    totalcost: 0,
    imagerequestcount: 0,
    gtinfee_id: 0,
    cost: 0,
    registrationid: registrationid,
  });

  // console.log("max", maxImage, additionalsInfo.imagerequestcount);
  const handleChange = (e) => {
    // console.log(e.target.value);
    const range = e.target.value;
    if (range != 0 && Number(range)) {
      const coo = gtinFees.find((x) => x.id == range);
      var amountToPay = coo.additionalFee;
      var max = coo.numberOfGtins;
    }

    setMaxImage(max);
    setAdditionalInfo({
      ...additionalsInfo,
      cost: amountToPay,
      gtinfee_id: range,
    });
  };

  const maxImageSetter = (e) => {
    const pegAt = maxImage;
    const selected = e.target.value;
    if (selected > pegAt) {
      toast.warn("Images cannot be more than selected range");
      setAdditionalInfo({
        ...additionalsInfo,
        imagerequestcount: Number(selected),
      });
    } else {
      setAdditionalInfo({
        ...additionalsInfo,
        imagerequestcount: Number(selected),
      });
    }
  };

  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  const formatCostF = () => {
    const { imagerequestcount, cost } = additionalsInfo;

    if (imagerequestcount == 0 && cost > 0) {
      setAdditionalInfo({ ...additionalsInfo, totalcost: cost });
      // return cost
    } else if (imagerequestcount > 0 && imagerequestcount <= 50) {
      const pay = imagerequestcount * 2000 + cost;
      setAdditionalInfo({ ...additionalsInfo, totalcost: pay });
      // return formatCost(pay)
    } else if (imagerequestcount == 0 && imagerequestcount <= 50) {
      const pay = imagerequestcount * 2000 + cost;
      setAdditionalInfo({ ...additionalsInfo, totalcost: pay });
      // return formatCost(pay)
    } else if (imagerequestcount > 50 && imagerequestcount <= 100) {
      const pay = imagerequestcount * 1500 + cost;
      setAdditionalInfo({ ...additionalsInfo, totalcost: pay });
      // return formatCost(pay)
    } else if (imagerequestcount > 100 && imagerequestcount <= 400) {
      const pay = imagerequestcount * 1500 + cost;
      setAdditionalInfo({ ...additionalsInfo, totalcost: pay });
      // return formatCost(pay)
    } else if (imagerequestcount > 499) {
      toast.warning(
        "For images up to 500 please contact the admin for price thank you"
      );
      const pay = imagerequestcount * 1500 + cost;
      setAdditionalInfo({ ...additionalsInfo, totalcost: pay });
      // return formatCost(pay)
    }
  };
  useEffect(() => {
    formatCostF();
  }, [additionalsInfo.imagerequestcount, additionalsInfo.gtinfee_id]);

  const gtindrop = gtinFees.map((item) => {
    const gtn = {};

    gtn.value = item.id;
    gtn.label = item.description;
    gtn.cost = item.price;
    return gtn;
  });
  // console.log("det", gtindrop)
  const modifiedGtn = [
    {
      value: "--select--",
      label: "--select a valid option--",
    },
  ];
  const concatDet = modifiedGtn.concat(gtindrop);

  const requestForAdditional = async () => {
    try {
      setLoading(true);
      const sendDet = await additionalRequest(additionalsInfo);
      if (sendDet.status == 201 || (200 && sendDet.data.isSuccess == true)) {
        toast.success("Additional request completed, kindly check your email for your invoice details");
        setLoading(false);
        // console.log("something happened", sendDet);
      } else {
        toast.info("You have a pending request");
        setLoading(false);
      }
    } catch (er) {
      toast.warn("plase try again", er);
      setLoading(false);
    }
  };

  const showBUttonOrNot = () => {
    if (additionalsInfo.gtinfee_id === "16") {
      return (
        <p style={{ color: "red" }}>
          Please contact the admin to request for additional barcode up to 2000
        </p>
      );
    } else if (
      additionalsInfo.gtinfee_id === "--select--" ||
      additionalsInfo.gtinfee_id === 0 ||
      additionalsInfo.imagerequestcount > maxImage
    ) {
      return <></>;
    } else {
      return (
        <button
        disabled={loading}
          className="bg-gs1-blue h-10 text-white w-32"
          component="a"
          role="button"
          onClick={() => requestForAdditional()}
        >
          {loading && (
            <CSpinner component="span" size="sm" aria-hidden="true" />
          )}
          Make request
        </button>
      );
    }
  };

  return (
    <>
      <div className="px-3 py-3">
        <CCard>
          <CCardBody className="gtin-card">
            <h3 className="">Request additional barodes</h3>
            <p className="text-[14px]">
              Please note that validty is one year
              <br />
              <b>
                {" "}
                Account Name:GS1 System Nigeria, Account Number: 2015342894,
                Bank Name:FBN{" "}
              </b>
            </p>
            <hr />
            <div className="lg:columns-3 sm:columns-1">
              <div>
                <div className="mb-3">
                  <label>Select number range</label>
                  <CFormSelect
                    className="px-2 h-[50px] w-full outline outline-outline outline-1 border-b-2 border-gs1-blue  my-2"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    aria-label="Default select example"
                    options={concatDet}
                  />
                  {showBUttonOrNot()}
                </div>
                {/* Hides button if request is not met */}
              </div>
              <div className="invisible">
                How many images do you need?
                <br />
                <input
                  className="px-2 h-[50px] w-full outline outline-outline outline-1 border-b-2 border-gs1-blue my-2"
                  type="number"
                  name="imageCount"
                  min="0"
                  max={maxImage}
                  onChange={(e) => maxImageSetter(e)}
                />
              </div>
              <div>
                <CCard style={{ width: "18rem" }}>
                  <CCardBody className="border-b-2 border-gs1-blue">
                    {<FcMoneyTransfer size={40} />}
                    <h1>
                      {additionalsInfo.imagerequestcount === 0
                        ? formatter.format(additionalsInfo.cost)
                        : formatter.format(additionalsInfo.totalcost)}
                    </h1>
                  </CCardBody>
                </CCard>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </div>
    </>
  );
};

export default AdditionalBarcodes;
